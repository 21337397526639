import React from "react";
import { motion } from "framer-motion";
import "./About.css";

function About() {
    const titleVariants = {
        hidden: { opacity: 0, y: 75, x: 0 },
        visible: { opacity: 1, y: 0, x: 0 },
    };

    const subtitleVariants = {
        hidden: { opacity: 0, y: 50, x: 0 },
        visible: { opacity: 1, y: 0, x: 0 },
    };

    const paragraphVariants1 = {
        hidden: { opacity: 0, y: 50, x: 50 },
        visible: { opacity: 1, y: 0, x: 0 },
    };

    const paragraphVariants2 = {
        hidden: { opacity: 0, y: 50, x: 50 },
        visible: { opacity: 1, y: 0, x: 0 },
    };

    const contactVariants = {
        hidden: { opacity: 0, y: 0, x: 50 },
        visible: { opacity: 1, y: 0, x: 0 },
    };

    return (
        <section id="about">
            <div>
                <div id="about-title">
                    <motion.div
                        variants={titleVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.2 }}
                        transition={{ duration: 0.4, delay: 0 }}
                    >
                        <h1>
                            xsiadron<span>{"[Luke\u00A0Frydrych]"}</span>
                        </h1>
                    </motion.div>
                    <motion.div
                        variants={subtitleVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.2 }}
                        transition={{ duration: 0.4, delay: 0.1 }}
                    >
                        <small>
                            Indie
                            <ul>
                                <span>&#9898;</span>
                                <li className="underline" id="game-button">
                                    game
                                </li>
                                <span>&#9898;</span>
                                <li className="underline">app</li>
                                <span>&#9898;</span>
                                <li className="underline">web</li>
                            </ul>
                            developer
                        </small>
                    </motion.div>
                    <motion.div
                        variants={paragraphVariants1}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.2 }}
                        transition={{ duration: 0.4, delay: 0.2 }}
                    >
                        <p>
                            <b>Hey!</b> I'm a creative programmer from Poland,
                            actively contributing on a global scale.
                            <br />
                            Passionate about crafting precise and high-quality projects.
                            <br />
                            <br />
                            <div id="build_projects_text_block">
                                My projects are built in
                                <div id="build_projects">
                                    <span>Unreal&nbsp;Engine</span>{" "}
                                    <span>Android&nbsp;Studio</span>{" "}
                                    <span>
                                        2D&nbsp;Graphics&nbsp;Software
                                    </span>{" "}
                                    <span>
                                        3D&nbsp;Graphics&nbsp;Software
                                    </span>{" "}
                                    <span>
                                        Audio&nbsp;&&nbsp;Video&nbsp;Editing&nbsp;Software
                                    </span>
                                    <span>&&nbsp;various&nbsp;IDEs</span>
                                </div>
                            </div>
                        </p>
                        <br />
                    </motion.div>

                    <motion.div
                        variants={paragraphVariants2}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.2 }}
                        transition={{ duration: 0.4, delay: 0.3 }}
                    >
                        <p>
                            I develop in&ensp;
                            <span>C++</span> <span>C#</span>{" "}
                            <span>JavaScript(React, Node.js)</span>{" "}
                            <span>SQL</span> <span>Python</span>{" "}
                            <span>Lua</span>
                            <span>Java</span> <span>&&nbsp;Kotlin</span>
                        </p>
                        <br />
                        <br />
                    </motion.div>

                    <motion.div
                        variants={contactVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.2 }}
                        transition={{ duration: 1, delay: 0.75 }}
                    >
                        <p>
                            Interested in working together?{" "}
                            <a href="mailto:lukasz.frydrych@xsiadron.com">
                                <b>Feel&nbsp;free&nbsp;to&nbsp;reach out!</b>
                            </a>
                        </p>
                    </motion.div>
                </div>
            </div>
        </section >
    );
}

export default About;
